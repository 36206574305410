
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    spoiled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { isSpoiled: this.spoiled };
  },
  methods: {
    spoil() {
      this.isSpoiled = true;
    },
  },
});
