
import emojiParser from "@/utils/emojiParser";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["emoji"],
  computed: {
    src(): string {
      return emojiParser.twemojiPath(this.emoji.unicode);
    },
  },
});
