
import hljs from "highlight.js";
import "highlight.js/styles/night-owl.css";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    lang: String,
    value: String,
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value || "");
    },
  },
  computed: {
    langName(): string | undefined {
      return hljs.getLanguage(this.lang as string)?.name;
    },
    // codeOrlanguageName: string, optionsOrCode: string | HighlightOptions, ignoreIllegals?: boolean | undefined, continuation?: Mode | undefined
    highlightedValue(): string {
      return hljs.highlight(this.value as string, {
        ignoreIllegals: true,
        language: this.lang as string,
      })?.value;
    },
  },
});
